'use client';

import {
  Typography, Box, Grid, SxProps,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  useCallback, useMemo, useState, useEffect,
} from 'react';

import Link from 'next/link';
import theme from '@/theme';
import { CategorySeo } from '@/lib/types/interfaces/CategorySeo';
import { getCategoriesSeo } from '@/lib/api/functions';
import { handleGTMEvent } from '@/lib/utils/functions';

interface Props {
  title: string
  seoCategories: CategorySeo[],
  sx?: SxProps,
  padding?: {}
}

const ServicesList = ({
  seoCategories, sx, padding, title,
}: Props) => {
  const [categories, setCategories] = useState<CategorySeo[]>([]);
  const [isShowAll, setShowAll] = useState(false);

  useEffect(() => {
    setCategories(seoCategories);
  }, [seoCategories]);

  const handleGetAllCategories = useCallback(async () => {
    if (!isShowAll) {
      const allCategories = await getCategoriesSeo('?limit=80');
      setShowAll(true);
      setCategories(allCategories?.data || []);
      return;
    }
    setCategories((prevState) => prevState.slice(0, 10));
    setShowAll(false);
  }, [isShowAll]);

  const renderServicesList = useMemo(() => categories?.map((item: CategorySeo) => {
    const link = `/services/${item.categorySlug}/${item.subCategorySlug}`;
    return (
      <Grid
        item
        lg={12 / 5}
        xs={12}
        key={item.id}
      >
        <Box>
          <Box onClick={() => handleGTMEvent(link, 'click_on_seo_link')}>
            <Link
              href={link}
              aria-label={`Go to hire ${item.categorySlug} ${item.subCategorySlug}`}
            >
              <Typography
                variant='body1'
                component='h3'
                color={theme.palette.success.main}
              >
                {item.subCategoryName}
              </Typography>
            </Link>
          </Box>
        </Box>

      </Grid>
    );
  }), [categories]);

  return (
    <Box
      sx={sx}
      padding={padding}
    >
      <Typography
        variant='h6'
        fontWeight={600}
        component='h2'
      >
        {title}
      </Typography>

      <Box sx={{ marginTop: 3 }}>
        <Grid
          container
          spacing={1}
        >
          {renderServicesList}
        </Grid>
      </Box>

      <Typography
        variant='body1'
        component='div'
        color={theme.palette.success.main}
        sx={{
          marginTop: 3,
          display: 'flex',
          cursor: 'pointer',
          [theme.breakpoints.up('lg')]: {
            justifyContent: 'center',
          },
        }}
        onClick={handleGetAllCategories}
      >
        {!isShowAll ? 'Show' : 'Hide'}
        {' '}
        all
        {' '}
        {!isShowAll ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </Typography>
    </Box>
  );
};

export default ServicesList;
