'use client';

import Link from 'next/link';
import { Button } from '@mui/material';
import theme from '@/theme';

import Logo from '@/components/Logo';
import styles from './Header.module.scss';
import { headerControlsHeight } from '@/constants';

const Header = () => (
  <header className='header-container'>
    <Link
      href='/'
      className={styles.link}
      aria-label='Get back to main page'
    >
      <Logo />
    </Link>
    <Link
      href='/add-listing'
      aria-label='Go to listing'
      legacyBehavior
      passHref
    >
      <Button
        href='/add-listing'
        variant='outlined'
        sx={{
          height: headerControlsHeight,
          [theme.breakpoints.up('md')]: { width: 197 },
        }}
      >
        Add listing
      </Button>
    </Link>
  </header>
);

export default Header;
